import React from "react"
import styles from "./mobile-menu.module.css"
import { Link } from "gatsby"

export default ({ handleSubmit, handleUpdate, state }) => (
    <nav role="navigation"
        css={{
            [`@media (min-width: 650px)`]: {
            display: `none`,
        },
        marginTop: `-30px`}}
        >
        <div className={styles['menuToggle']}>    
        <input type="checkbox" />    
        <span></span>
        <span></span>
        <span></span>
        
        <ul className={styles['menu']}>

            <Link
                to="/"
            >
                <li> Home </li>
            </Link>

            <Link
                to="/skills/"
            >
            <li> Skills </li>
            </Link>

            <Link
                to="/projects/"
            >
            <li> Projects </li>
            </Link>

            <Link
                to="/career/"
            >
            <li> Career </li>
            </Link>

            <Link
                to="/solutions/"
            >
            <li> Solutions </li>
            </Link>


            <Link
                to="/newsletter/"
            >
            <li> Newsletter </li>
            </Link>

            <Link
                to="/contact/"
            >
            <li> Contact </li>
            </Link>

        </ul>
        </div>
    </nav>
);