import React from "react"
import Helmet from 'react-helmet'
import { css } from "react-emotion"
import { rhythm, scale } from "../utils/typography"
import styles from "./layout.module.css"
import { StaticQuery, Link, graphql } from "gatsby"
import MobileMenu from './mobile-menu';
require("prismjs/plugins/line-numbers/prism-line-numbers.css")

export default ({ children, q }) => (
  <StaticQuery
    query={graphql`
      query {
        site {
          siteMetadata {
            title
          }
        }
      }
    `
    }
    render={data => (
      <div
      css={{
        background: `rgba(0,0,0,0.03)`,
        minHeight: `100vh`,
      }}
      >
      <Helmet>
        <title>{data.site.siteMetadata.title}</title>
      </Helmet>
      <div
        css={{
          background: `white`,
          borderBottom: `1px solid rgba(0,0,0,0.08)`,
        }}
      >
      <MobileMenu> </MobileMenu>
        <div
          css={{
            padding: rhythm(3 / 4),
            paddingBottom: `calc(${rhythm(3 / 4)} - 1px)`,
            maxWidth: 960,
            margin: `0 auto`,
            overflow: `hidden`,
          }}
        >

          <Link
            to="/"
            css={{
              display: `inline-block`,
              float: `left`,
              textDecoration: `none`,
            }}
          >
            <h1
              data-testid="site-title"
              css={{
                ...scale(4 / 5),
                lineHeight: 1,
                margin: 0,
                overflow: `hidden`,
                [`@media (max-width: 650px)`]: {
                  display: `none`,
                },
              }}
            >
              <span
                css={{
                  lineHeight: 1,
                }}
              >
                Claudio Teixeira
              </span>
            </h1>
            <h1
              data-testid="site-title"
              css={{
                ...scale(4 / 5),
                lineHeight: 1,
                margin: 0,
                overflow: `hidden`,
                paddingLeft: `100px`,
                [`@media (min-width: 650px)`]: {
                  display: `none`,
                },
              }}
            >
              <span
                css={{
                  lineHeight: 1,
                }}
              >
                Claudio T.
              </span>
            </h1>
          </Link>
         <Link
            
            to="/contact/"
            css={{
              color: `inherit`,
              display: `inline-block`,
              float: `right`,
              lineHeight: `35px`,
              textDecoration: `none`,
              [`@media (max-width: 650px)`]: {
                display: `none`,
              },
            }}
          >
            Contact
          </Link>

          <Link
            
            to="/newsletter/"
            css={{
              color: `inherit`,
              display: `inline-block`,
              float: `right`,
              lineHeight: `35px`,
              marginRight: `15px`,
              textDecoration: `none`,
              [`@media (max-width: 650px)`]: {
                display: `none`,
              },
            }}
          >
            Newsletter
          </Link>


          <Link
            
            to="/solutions/"
            css={{
              color: `inherit`,
              display: `inline-block`,
              float: `right`,
              lineHeight: `35px`,
              marginRight: `15px`,
              textDecoration: `none`,
              [`@media (max-width: 650px)`]: {
                display: `none`,
              },
            }}
          >
            Solutions
          </Link>



          <Link
            
            to="/career/"
            css={{
              color: `inherit`,
              display: `inline-block`,
              float: `right`,
              lineHeight: `35px`,
              marginRight: `15px`,
              textDecoration: `none`,
              [`@media (max-width: 650px)`]: {
                display: `none`,
              },
            }}
          >
            Career
          </Link>

          <Link
            
            to="/projects/"
            css={{
              color: `inherit`,
              display: `inline-block`,
              float: `right`,
              lineHeight: `35px`,
              marginRight: `15px`,
              textDecoration: `none`,
              [`@media (max-width: 650px)`]: {
                display: `none`,
              },
            }}
          >
            Projects
          </Link>

          <Link
            
            to="/skills/"
            css={{
              color: `inherit`,
              display: `inline-block`,
              float: `right`,
              lineHeight: `35px`,
              marginRight: `15px`,
              textDecoration: `none`,
              [`@media (max-width: 650px)`]: {
                display: `none`,
              },
            }}
          >
            Skills
          </Link>
          <Link
            
            to="/"
            css={{
              color: `inherit`,
              display: `inline-block`,
              float: `right`,
              lineHeight: `35px`,
              marginRight: `15px`,
              textDecoration: `none`,
              [`@media (max-width: 650px)`]: {
                display: `none`,
              },
            }}
          >
            /
          </Link>
        </div>
      </div>
      <div
         css={{
          padding: rhythm(3 / 4),
          paddingBottom: `calc(${rhythm(3 / 4)} - 1px)`,
          maxWidth: 960,
          margin: `0 auto`,
          overflow: `hidden`,
        }}
      >
        {children}
        
        --        
        <footer>
        <small> <a href="mailto:contact@claudioteixeira.com"> </a> Made in <b>Portugal </b> 🇵🇹 </small>          
        </footer>
         </div>
      </div>
    )}
  />
)